import React, {useState} from "react";
import {Product} from "../@types/response";
import {EuiFlexGroup, EuiFlexItem, EuiModal, EuiModalBody, EuiModalFooter, EuiTitle} from "@elastic/eui";
import {EuiButton} from "@elastic/eui";
import {css} from "@emotion/react";

interface DetailModalProp {
  product: Product;
}

function DetailModal(props: DetailModalProp) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => {
    setIsModalVisible(false);
  };
  const showModal = () => setIsModalVisible(true);

  const {product} = props;

  let modal;

  console.log("modal visible", isModalVisible);

  if (isModalVisible) {
    modal = (
        <EuiModal onClose={closeModal}>
          <EuiModalBody>
            <EuiFlexGroup direction={'column'}>
              <EuiFlexItem>
                <EuiTitle size={'xs'}><h1>Product Description:</h1></EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem>
                <div
                    className={"container"}
                    dangerouslySetInnerHTML={{__html: product.bodyHtml}}
                    style={{maxHeight: 300, overflowY: "scroll"}}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={closeModal} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
    );
  }

  return (
      <>
        <EuiFlexItem className={"detail-link"} onClick={showModal} css={css`align-items: center; margin-top: 10px`}>
          More Details
        </EuiFlexItem>
        {modal}
      </>
  );
}

export default DetailModal;
