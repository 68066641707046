import { addToast } from "../components/Toast";
import { AllResults } from "../@types/result";
import { calculateScore } from "./scoreCalculator";

export const shareResults = async (results: AllResults) => {
  const points = calculateScore(results);
  const emojis = results.map((r) => r.score.symbol).join("");
  const message = `I scored ${points} points playing https://pricing.games!\n\n${emojis}`;
  if (navigator.share) {
    try {
      await navigator
        .share({ text: message })
        .then(() =>
          console.log("Hooray! Your content was shared to tha world")
        );
    } catch (error) {
      console.log(`Oops! I couldn't share to the world because: ${error}`);
    }
  } else if (navigator.clipboard) {
    navigator.clipboard.writeText(message);
    addToast({
      title: "Your score was copied to your clipboard!",
      color: "success",
    });
  } else {
    // fallback code
    console.log(
      "Web share is currently not supported on this browser. Please provide a callback"
    );
  }
};
