import {EuiFlexGroup, EuiFlexItem} from "@elastic/eui";
import {FC} from "react";
import {AllResults} from "../@types/result";
import {css} from "@emotion/react";


interface EmojiBarProps {
  results: AllResults
}

export const EmojiBar: FC<EmojiBarProps> = ({results}) => {
  return (
      <EuiFlexGroup gutterSize={'xs'} responsive={false}>
        {results.map((result, index) => (
            <EuiFlexItem css={css`
            font-size: 1.6em;
            `} key={`point-progress-bar-${index}`}>
              {result.score.symbol}
            </EuiFlexItem>
        ))}
      </EuiFlexGroup>
  )
}
