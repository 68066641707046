import mixpanel from "mixpanel-browser";

const userArrived = function () {
  mixpanel.track("page_viewed");
};

const clickGameButton = function (buttonName: string) {
  mixpanel.track(`${buttonName}_button_clicked`);
};

export { userArrived, clickGameButton };
