import React from "react";
import "@elastic/eui/dist/eui_theme_light.css";

import ReactDOM from "react-dom/client";
import { App } from "./App";
import { ProductGuesses, Results } from "./components/Results";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { store } from "./features/redux/store";
import { Provider } from "react-redux";
import { EuiProvider } from "@elastic/eui";
import { Routes, Route } from "react-router-dom";
import { Global } from "@emotion/react";
import { ReactQueryDevtools } from "react-query/devtools";
import mixpanel from "mixpanel-browser";
import { GlobalToast } from "./components/Toast";

mixpanel.init("d353424d38402d14f4020d19166b6b4e", { debug: false });

mixpanel.init("d353424d38402d14f4020d19166b6b4e", { debug: false });

const persistor = persistStore(store);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 5 * 60 * 1000,
    },
  },
  queryCache: new QueryCache({}),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null} />
          <EuiProvider>
            <Global
              styles={{
                "*:focus:focus-visible": {
                  outlineStyle: "none",
                },
              }}
            />
            <ReactQueryDevtools initialIsOpen={false} />
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/results" element={<Results />} />
            </Routes>
            <GlobalToast />
          </EuiProvider>
        </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
