import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import React, { FC, ReactNode } from "react";
import { Product } from "../@types/response";
import { css } from "@emotion/react";
import { EmojiBar } from "./EmojiBar";
import { calculateScore } from "../features/scoreCalculator";
import { useSelector } from "react-redux";
import { AppState } from "../@types";
import { useWindowDimensions } from "../features/hooks";

interface CardProps {
  product: Product;
  children: ReactNode;
  cardFlip: boolean;
}

export const Card: FC<CardProps> = ({ children, product, cardFlip }) => {
  const data = useSelector((state: AppState) => state.localData);
  const { height, width } = useWindowDimensions();

  return (
    <EuiPanel
      css={css`
        max-width: 400px;
        height: calc(100vh - 280px);
      `}
    >
      <EuiFlexGroup direction={"column"} alignItems={"center"} gutterSize={"m"}>
        <EuiFlexItem className={"emoji-bar"}>
          <EuiFlexGroup
            gutterSize={"none"}
            alignItems={"flexEnd"}
            direction={"row"}
            responsive={false}
            justifyContent={"spaceBetween"}
          >
            <EuiFlexItem
              css={css`
                align-items: end;
              `}
              grow={false}

            >
              <EuiText><b>{data.step + 1} / 10</b></EuiText>
            </EuiFlexItem>
            <EuiFlexGroup gutterSize={"s"} direction={"column"}
             responsive={false}>
              <EuiFlexItem
                css={css`
                  align-items: end;
                `}
                grow={false}
              >
                <EmojiBar results={data.results} />
              </EuiFlexItem>
              <EuiFlexItem
                css={css`
                  align-items: end;
                `}
                grow={false}
              >
                <EuiText>
                  <b>{calculateScore(data.results)} / 40 Points</b>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem
          css={css`
            align-items: center;
          `}
        >
          {cardFlip ? (
            <div style={{ fontSize: 150 }}>
              {data.results[data.step].score.symbol}
            </div>
          ) : (
            <img
              style={{ width: "25vh", maxHeight: "25vh" }}
              src={product.imgSrc}
              alt={product.productTitle}
            />
          )}
        </EuiFlexItem>
        <EuiFlexItem
          css={css`
            align-items: center; text-align: center
          `}
        >
          <EuiTitle size={"xs"}>
            <h1>{product.productTitle}</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>{children}</EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};
