import React, { FC } from "react";
import { EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import { css } from "@emotion/react";
interface GuessResultProps {
  guess: number | undefined;
  answer: number | undefined;
  score: number;
}
export const GuessResult: FC<GuessResultProps> = ({ guess, answer, score }) => {
  return (
    <EuiFlexGroup direction={"column"}  alignItems={"center"} gutterSize={"none"}>
      <EuiFlexItem grow={false} css={css`align-items: center`}>
        <EuiText size={"m"}>
          <span style={{ fontWeight: "bold" }}>Your Guess: </span>${guess}
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false} css={css`align-items: center`}>
        <EuiText size={"m"}>
          <span style={{ fontWeight: "bold" }}>Correct Answer: </span>${answer}
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false} css={css`align-items: center`}>
        <EuiText size={"m"}>
          <span style={{ fontWeight: "bold" }}>You Earned: </span>
          {score}
          <span>{score === 1 ? " point" : " points"}</span>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
