import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalDataState } from "../../@types/localDataState";
import { DateTime } from "luxon";
import { BRONZE, GOLD } from "../scoreCalculator";
import { SingleResult } from "../../@types/result";

const initialState: LocalDataState = {
  init: false,
  date: DateTime.now().toFormat("yyyy-MM-dd").toString(),
  step: 0,
  maxSteps: 10,
  results: [],
};

export const slice = createSlice({
  name: "localData",
  initialState,
  reducers: {
    reset: (localDataState) => {
      Object.assign(localDataState, initialState);
    },
    nextStep: (localDataState) => {
      localDataState.step++;
    },
    guessMade: (localDataState, action: PayloadAction<SingleResult>) => {
      localDataState.results.push(action.payload);
    },
  },
});
export const { reset, nextStep, guessMade } = slice.actions;

export const localDataReducer = slice.reducer;
