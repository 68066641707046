import React, { useState } from "react";
import { EuiGlobalToastList } from "@elastic/eui";
import {Toast} from "../@types/toast";

let addToastHandler: any;
let removeAllToastsHandler: any;

export function addToast(toast: Toast) {
  if (!("id" in toast)) {
    toast.id = 'toast!'
  }
  addToastHandler(toast);
}

export function removeAllToasts() {
  removeAllToastsHandler();
}

export const GlobalToast = () => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  addToastHandler = (toast: Toast) => {
    setToasts(toasts.concat(toast));
  };

  const removeToast = (removedToast: Toast) => {
    setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
  };

  removeAllToastsHandler = () => {
    setToasts([]);
  };

  return (
    // @ts-ignore
    <EuiGlobalToastList
      style={{ margin: 12 }}
      toasts={toasts}
      dismissToast={removeToast}
      toastLifeTimeMs={6000}
    />
  );
};
