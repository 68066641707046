import {EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner} from "@elastic/eui";
import React from "react";


export const Loading = () => {
  return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiLoadingSpinner size="xl"/>
        </EuiFlexItem>
      </EuiFlexGroup>)

}