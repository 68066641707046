import React, {useState} from 'react';
import {
  EuiPageContent,
  EuiPage,
  EuiPageContentBody, EuiFlexGroup,
  EuiPageBody, EuiImage, EuiFlexItem,
} from '@elastic/eui';
import {useFetchInitialProducts} from "./api/product";
import {Game} from "./components/Game";
import {Loading} from "./components/Loading";
import {css} from "@emotion/react";
import IntelligemsLogo from "./logo_sideways_dark_color.png";
import './App.css';
import {useWindowDimensions} from "./features/hooks";

export const App = () => {
  const {data, isLoading} = useFetchInitialProducts()
  const {width, height} = useWindowDimensions()

  return (
      <EuiPage paddingSize="m" css={css`height: 100vh; padding: 0px`}>
        <EuiPageBody panelled={false} paddingSize={width >= 575 ? 'l' : 'none'} style={{marginLeft: "auto", marginRight: "auto"}}>
          <EuiPageContent
              hasBorder={false}
              hasShadow={false}
              paddingSize="none"
              color="transparent"
              borderRadius="none"
          >
            <EuiPageContentBody restrictWidth paddingSize={"m"}>
              <EuiFlexGroup direction={'column'} alignItems={'center'} gutterSize={width >= 575 ? 'l' : 'none'}>
                <EuiFlexItem grow={false} css={css`align-items: center`}>
                  <EuiImage
                      size="m"
                      hasShadow={false}
                      alt={'logo'}
                      caption=""
                      src={IntelligemsLogo}
                  />
                </EuiFlexItem>
                {isLoading || !data ? <Loading/> :
                    <Game products={data.products}/>}
              </EuiFlexGroup>
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
  );
}
