import { Point } from "../@types/points";
import { sumBy } from "lodash";
import { AllResults } from "../@types/result";

export const BULLSEYE: Point = {
  symbol: "🎯",
  points: 4,
  threshold: 0.95,
};

export const GOLD: Point = {
  symbol: "🥇",
  points: 3,
  threshold: 0.8,
};

export const SILVER: Point = {
  symbol: "🥈",
  points: 2,
  threshold: 0.5,
};

export const BRONZE: Point = {
  symbol: "🥉",
  points: 1,
  threshold: 0.2,
};

export const FAIL: Point = {
  symbol: "☠️",
  points: 0,
  threshold: -1,
};

export const getPoints = (actualPrice: number, guess: number): Point => {
  if (guess > actualPrice) return FAIL;
  if (guess > actualPrice * BULLSEYE.threshold) return BULLSEYE;
  if (guess > actualPrice * GOLD.threshold) return GOLD;
  if (guess > actualPrice * SILVER.threshold) return SILVER;
  if (guess > actualPrice * BRONZE.threshold) return BRONZE;
  return FAIL;
};

export const calculateScore = (points: AllResults) => {
  return sumBy(points, (item) => item.score.points);
};
