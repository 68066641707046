import axios, {AxiosRequestConfig, Method} from "axios";

const apiClient = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
});


const _request = (method: Method, url: string, config?: AxiosRequestConfig) =>
    apiClient.request({
      method,
      url,
      ...config,
    });

export const api = {
  get: <T>(url: string, config?: AxiosRequestConfig) =>
      _request("GET", url, {...config}),
  post: <T>(url: string, data: any, config?: AxiosRequestConfig) =>
      _request("POST", url, {data, ...config}),
  patch: <T>(url: string, data: any, config?: AxiosRequestConfig) =>
      _request("PATCH", url, {data, ...config}),
  delete: <T>(url: string, config?: AxiosRequestConfig) =>
      _request("DELETE", url, {...config}),
};