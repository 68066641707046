import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiText,
  EuiBadge,
  EuiStat,
  EuiHorizontalRule,
  EuiButton,
} from "@elastic/eui";
import { css } from "@emotion/react";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../@types";
import { ProductsResponse } from "../@types/response";
import { FetchInitialProducts, useFetchInitialProducts } from "../api/product";
import { shareResults } from "../features/share";
import IntelligemsLogo from "../logo_sideways_dark_color.png";
import { EmojiBar } from "./EmojiBar";

interface ResultsProps {
  productsParams?: undefined;
}

function formatCurrency(money: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(money);
}

export const Results = ({ productsParams = undefined }: ResultsProps) => {
  const { data } = useFetchInitialProducts(productsParams);
  const results = useSelector((state: AppState) => state.localData.results);
  /*  const results = [
    {
      guess: 11.2,
      actual: 14.6,
      score: {
        symbol: "🥈",
        points: 2,
        threshold: 0.5,
      },
      index: 7,
    },
    {
      guess: 14.2,
      actual: 13.1,
      score: {
        symbol: "🥇",
        points: 3,
        threshold: 0.8,
      },
      index: 6,
    },
    {
      guess: 11.2,
      actual: 14.6,
      score: {
        symbol: "🥈",
        points: 2,
        threshold: 0.5,
      },
      index: 7,
    },
    {
      guess: 14.2,
      actual: 13.1,
      score: {
        symbol: "🥇",
        points: 3,
        threshold: 0.8,
      },
      index: 6,
    },
    {
      guess: 11.2,
      actual: 14.6,
      score: {
        symbol: "🥈",
        points: 2,
        threshold: 0.5,
      },
      index: 7,
    },
    {
      guess: 14.2,
      actual: 13.1,
      score: {
        symbol: "🥇",
        points: 3,
        threshold: 0.8,
      },
      index: 6,
    },
    {
      guess: 11.2,
      actual: 14.6,
      score: {
        symbol: "🥈",
        points: 2,
        threshold: 0.5,
      },
      index: 7,
    },
    {
      guess: 14.2,
      actual: 13.1,
      score: {
        symbol: "🥇",
        points: 3,
        threshold: 0.8,
      },
      index: 6,
    },
    {
      guess: 11.2,
      actual: 14.6,
      score: {
        symbol: "🥈",
        points: 2,
        threshold: 0.5,
      },
      index: 7,
    },
    {
      guess: 14.2,
      actual: 13.1,
      score: {
        symbol: "🥇",
        points: 3,
        threshold: 0.8,
      },
      index: 6,
    },
  ];*/

  if (!data?.products) {
    return null;
  }

  const products = data.products as ProductsResponse["products"];
  const totalScore = results.reduce(
    (acc, result) => acc + result.score.points,
    0
  );

  return (
    <EuiFlexGroup
      direction={"column"}
      alignItems={"center"}
      gutterSize={"m"}
      css={css`
        gap: 0;
      `}
    >
      <hr />
      <EuiPage
        paddingSize="m"
        css={css`
          max-width: 60rem;
        `}
      >
        <EuiPageBody panelled={false} paddingSize={"m"}>
          <EuiPageContent
            hasBorder={false}
            hasShadow={false}
            paddingSize="none"
            color="transparent"
            borderRadius="none"
          >
            <EuiPageContentBody restrictWidth>
              <EuiStat title={totalScore} description="Your Score" />
              <EuiFlexItem
                css={css`
                  align-items: end;
                `}
                grow={false}
              >
                <EmojiBar results={results} />
              </EuiFlexItem>
              <EuiFlexGroup
                direction={"column"}
                alignItems={"flexStart"}
                gutterSize={"m"}
                css={css`
                  gap: 2rem;
                `}
              >
                <EuiHorizontalRule margin="m" />
                {results.map(
                  ({ guess, actual, score: { symbol, points }, index }) => {
                    const product = products[index];

                    return (
                      <EuiFlexGroup
                        direction={"row"}
                        alignItems={"flexStart"}
                        gutterSize={"s"}
                        css={css`
                          gap: 1rem;
                          width: 100%;
                        `}
                      >
                        <div style={{ position: "relative", width: "100%" }}>
                          <EuiFlexGroup
                            direction={"row"}
                            alignItems={"flexStart"}
                            gutterSize={"s"}
                            css={css`
                              gap: 0.2rem;
                              flex-wrap: nowrap;
                              overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                              max-width: 100%;
                            `}
                          >
                            <EuiImage
                              size="s"
                              hasShadow={false}
                              alt={product.productTitle}
                              caption=""
                              src={product.imgSrc}
                              css={css`
                                object-fit: cover;
                                width: 3rem !important;
                                height: 3rem !important;
                                filter: brightness(80%);
                                clip-path: polygon(
                                  20% 100%,
                                  0% 80%,
                                  0% 0%,
                                  80% 0%,
                                  100% 20%,
                                  100% 100%
                                );
                              `}
                            />
                            <EuiFlexGroup
                              direction={"column"}
                              alignItems={"flexStart"}
                              gutterSize={"s"}
                              css={css`
                                gap: 0.5rem;
                                flex-wrap: nowrap;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                max-width: 100%;
                                flex-grow: 1;
                              `}
                            >
                              <EuiText
                                css={css`
                                  font-weight: bold;
                                  overflow: hidden;
                                  white-space: nowrap;
                                  text-overflow: ellipsis;
                                  font-size: 0.9em;
                                `}
                              >
                                {product.productTitle}
                              </EuiText>
                              <EuiFlexGroup
                                direction={"column"}
                                alignItems={"flexStart"}
                                gutterSize={"s"}
                                css={css`
                                  gap: 1rem;
                                  flex-wrap: nowrap;
                                  overflow: hidden;
                                  white-space: nowrap;
                                  text-overflow: ellipsis;
                                  max-width: 100%;
                                  width: 100%;
                                `}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flex: "auto",
                                    flexDirection: "row",
                                    width: "100%",
                                    lineHeight: "1.3em",
                                  }}
                                >
                                  <div
                                    style={{ flexGrow: 1, display: "block" }}
                                  >
                                    <EuiBadge color="success">
                                      +{points}
                                    </EuiBadge>{" "}
                                    Guessed: {formatCurrency(guess)}
                                  </div>
                                  <EuiBadge
                                    color="primary"
                                    css={css`
                                      float: right;
                                      display: block;
                                      font-weight: bold;
                                      padding-left: 0.3em;
                                      padding-right: 0.3em;
                                      letter-spacing: 0.001em;
                                      font-size: 0.8em;
                                    `}
                                  >
                                    {formatCurrency(actual)}
                                  </EuiBadge>
                                </div>
                              </EuiFlexGroup>
                            </EuiFlexGroup>
                            <EuiText
                              css={css`
                                font-size: 2rem;
                                position: absolute;
                                top: -0.5rem;
                                left: -0.9rem;
                              `}
                            >
                              {symbol}
                            </EuiText>
                          </EuiFlexGroup>
                        </div>
                      </EuiFlexGroup>
                    );
                  }
                )}
              </EuiFlexGroup>
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
      <EuiButton
        iconType={"share"}
        color="success"
        onClick={() => {
          shareResults(results);
        }}
        css={css`
          margin-top: 2em;
        `}
      >
        Share
      </EuiButton>
    </EuiFlexGroup>
  );
};

export const ProductGuesses = () => {
  return (
    <EuiFlexGroup direction={"column"} alignItems={"center"} gutterSize={"s"}>
      <EuiFlexItem
        grow={false}
        css={css`
          align-items: center;
        `}
      >
        <EuiImage
          size="m"
          hasShadow={false}
          alt={"logo"}
          caption=""
          src={IntelligemsLogo}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
