import { configureStore, Store } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistCombineReducers,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistConfig } from "redux-persist/es/types";
import {AppState} from "../../@types";
import {localDataReducer} from "./localData";

const persistConfig: PersistConfig<AppState> = {
  key: "root",
  storage,
  whitelist: [],
};

// Create the root reducer
const reducer = persistCombineReducers<AppState>(persistConfig, {
  localData: localDataReducer,
});

export const store: Store<AppState, any> = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: true,
});

export const persistor = persistStore(store);
