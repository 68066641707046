import React, { FC } from "react";
import { EuiButton } from "@elastic/eui";
import { clickGameButton } from "../mixpanel";
import {useWindowDimensions} from "../features/hooks";

interface GuessButtonProps {
  handleGuess: () => void;
}
const GuessButton: FC<GuessButtonProps> = ({ handleGuess }) => {
  const {width, height} = useWindowDimensions()
  return (
    <EuiButton
      onClick={() => {
        clickGameButton("guess");
        handleGuess();
        window.scrollTo(0, width / 2)
      }}
      color={"success"}
    >
      Guess
    </EuiButton>
  );
};

interface NextButtonProps {
  handleNext: () => void;
}

const NextButton: FC<NextButtonProps> = ({ handleNext }) => {
  return (
    <EuiButton
      color={"success"}
      onClick={() => {
        handleNext();
        clickGameButton("next");
      }}
    >
      Next
    </EuiButton>
  );
};

export { GuessButton, NextButton };
