import {Params} from "../@types";
import {ProductsResponse} from "../@types/response";
import { useQuery } from "react-query";

import { api } from "./api";

export const FetchInitialProducts = async (
  params?: Params
): Promise<ProductsResponse> => {
  const resp = await api.get<ProductsResponse>(
      "https://98fi2mncra.execute-api.us-east-1.amazonaws.com/products",
      //"https://intelli-pricing-games.s3.amazonaws.com/csvjson.json",
    {
      params,
    }
  );
  return resp.data;
}

export const useFetchInitialProducts = (params?: Params) =>
  useQuery<ProductsResponse, Error>(["initialProducts", params], () =>
    FetchInitialProducts(params)
  );