import {
  EuiButton,
  EuiCodeBlock,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from "@elastic/eui";
import React, {FC, useState} from "react";
import ReactCardFlip from "react-card-flip";
import {Card} from "./Card";
import {Product} from "../@types/response";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../@types";
import {nextStep} from "../features/redux/localData";
import {GuessResult} from "./GuessResult";
import {handleGuess} from "../features/guess";
import {GuessButton, NextButton} from "./GameButtons";
import DetailModal from "./DetailModal";
import {Results} from "./Results";
import {addToast} from "./Toast";
import {formatUrl} from "../features/util";
import {shareResults} from "../features/share";
import {css} from "@emotion/react";

interface GameProps {
  products: Product[];
}

export const Game: FC<GameProps> = ({products}) => {
  const [guessValue, setGuessValue] = useState("");
  const [cardFlip, setCardFlip] = useState(false);
  const data = useSelector((state: AppState) => state.localData);
  const maxSteps = useSelector((state: AppState) => state.localData.maxSteps);
  const stepIndex = useSelector((state: AppState) => state.localData.step);

  const dispatch = useDispatch();

  const product = products[stepIndex];

  const onChange = (e: any) => {
    setGuessValue(e.target.value);
  };

  const onGuessClick = (guess: string) => {
    if (guess == "") {
      addToast({title: "Guess a price to continue!", color: "warning"});
    } else {
      const guessFloat = parseFloat(guess);
      handleGuess(dispatch, product, guessFloat, stepIndex);
      setCardFlip((prev) => !prev);
    }
  };

  const handleGuessClick = function () {
    onGuessClick(guessValue);
  };

  const handleNext = function () {
    dispatch(nextStep());
    setCardFlip(false);
    setGuessValue("");
  };

  if (stepIndex > data.maxSteps - 1 || stepIndex > products.length - 1) {
    return <Results/>;
  }

  return (
      <>
        <EuiFlexItem grow={false}>
          <ReactCardFlip isFlipped={cardFlip} flipDirection={"horizontal"}>
            <Card cardFlip={cardFlip} product={product}>
              <br/>
              <EuiFlexGroup
                  gutterSize={"none"}
                  alignItems={"center"}
                  direction={"row"}
              ></EuiFlexGroup>
              <EuiFlexItem
                  css={css`
                text-align: center;
              `}
              >
                Sold by {formatUrl(product.siteUrl)}
              </EuiFlexItem>
              <DetailModal product={product}/>
            </Card>
            {data.results.length > 0 && data.results[data.step] && (
                <Card cardFlip={cardFlip} product={product}>
                  <EuiFlexGroup
                      gutterSize={"none"}
                      alignItems={"center"}
                      direction={"row"}
                  >
                    <GuessResult guess={parseFloat(guessValue)} answer={product.price}
                                 score={data.results[data.step].score.points}/>
                    <EuiFlexItem
                        grow={false}
                        css={css`
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                  `}
                    >
                      <EuiText size={"m"}>
                        👀 &nbsp;Check it out on&nbsp;
                        <a
                            target="_blank"
                            href={
                                product.siteUrl + "/products/" + product.productHandle
                            }
                        >
                          {formatUrl(product.siteUrl)}
                        </a>
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </Card>
            )}
          </ReactCardFlip>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFieldNumber
              inputMode={"decimal"}
              // pattern="[0-9]*"
              prepend={"$"}
              disabled={cardFlip}
              placeholder="Enter your guess here"
              value={guessValue}
              onChange={(e) => onChange(e)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {cardFlip ? (
              <NextButton handleNext={handleNext}/>
          ) : (
              <GuessButton handleGuess={handleGuessClick}/>
          )}
        </EuiFlexItem>
        {/*<EuiFlexItem>*/}
        {/*  <EuiCodeBlock language="json" fontSize="s" paddingSize="l">*/}
        {/*    {JSON.stringify(data, undefined, 2)}*/}
        {/*  </EuiCodeBlock>*/}
        {/*</EuiFlexItem>*/}
        {/*<EuiFlexItem>*/}
        {/*  <EuiCodeBlock*/}
        {/*    language="json"*/}
        {/*    fontSize="s"*/}
        {/*    paddingSize="l"*/}
        {/*    isVirtualized*/}
        {/*    overflowHeight={300}*/}
        {/*  >*/}
        {/*    {JSON.stringify(products, undefined, 2)}*/}
        {/*  </EuiCodeBlock>*/}
        {/*</EuiFlexItem>*/}
      </>
  );
};
