import {Product} from "../@types/response";
import {getPoints} from "./scoreCalculator";
import {Dispatch} from "@reduxjs/toolkit";
import {guessMade} from "./redux/localData";
import {SingleResult} from "../@types/result";

export const handleGuess = (dispatch: Dispatch, product: Product, guessPrice: number, index: number) => {
    const resultScore = getPoints(product.price, guessPrice);
    const result: SingleResult = {
        score: resultScore, guess: guessPrice, actual: product.price, index: index
    }
    dispatch(guessMade(result))
}
